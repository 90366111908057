import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import {
  Featured,
  Hero,
  OurMission,
  PopularShoeGuides,
  RecentArticles,
} from "../../components"

import { Layout } from "../../Layout"

interface HomeTemplateProps {}

const HomeTemplate: React.FC<HomeTemplateProps> = ({}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            description
            siteUrl
            title
          }
        }
      }
    `
  )

  const schema = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: site.siteMetadata.title,
    url: site.siteMetadata.siteUrl,
    potentialAction: {
      "@type": "SearchAction",
      target: "{search_term_string}",
      "query-input": "required name=search_term_string",
    },
  }
  const title = `Best shoe reviews, shoe buying guides, shoe picks`
  return (
    <Layout title={title} schemaMarkup={schema}>
      <Hero />
      <Featured />
      <RecentArticles />
      <PopularShoeGuides />
      <OurMission />
    </Layout>
  )
}
export default HomeTemplate
