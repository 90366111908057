import React from "react"
import {
  BlogPost,
  PostContainerInner,
  BlogPostContent,
  PostContainer,
  MainContainer,
  PageContainer,
  PageHeaders,
  PageHeading,
  PostArticle,
  PostContent,
  SectionSpacer,
} from "../../elements"
import { Layout } from "../../Layout"

interface DisclaimerTemplateProps {}

const DisclaimerTemplate: React.FC<DisclaimerTemplateProps> = ({}) => {
  return (
    <Layout
      title={`Disclaimer & Disclosure`}
      pathname="/disclaimer"
      description="We make every effort to provide you with the most effective and appropriate footwear guidelines possible. Our reviews and recommendations are properly researched, and we will never knowingly lead you astray. We receive a small commission if you make a purchase on Amazon within 24 hours after clicking an amazon reference link on our site. This enables us to remain... Continue reading our Disclaimer »"
    >
      <PageHeaders>
        <MainContainer>
          <PageHeading>
            <h2>Disclaimer</h2>
          </PageHeading>
        </MainContainer>
      </PageHeaders>

      <PageContainer>
        <MainContainer>
          <PostArticle>
            <PostContent>
              <p>
                We make every effort to provide you with the most effective and
                appropriate footwear guidelines possible.
              </p>
              <p>
                Our reviews and recommendations are properly researched, and we
                will never knowingly lead you astray.
              </p>
              <p>
                We receive a small commission if you make a purchase any of our
                recommended products on Amazon within 24 hours after clicking an
                amazon reference link on our site.
                <strong>
                  Note that this will not affect any of the prices of these
                  products
                </strong>
              </p>
              <p>
                Prices and product descriptions do sometimes fall out of date.
                Please shop wisely.
              </p>
              <p>
                Bestonyourfeet.com is a participant in the Amazon Services LLC
                Associates Program, an affiliate advertising program designed to
                provide a means for us to earn fees by linking Products to
                Amazon.com and affiliated sites.
              </p>
              <p>
                We may also participate in other affiliate programs or get paid
                to host relevant advertising on our blog.
              </p>
            </PostContent>
          </PostArticle>
        </MainContainer>
        <SectionSpacer marginBottom="3em" />
      </PageContainer>
    </Layout>
  )
}
export default DisclaimerTemplate
