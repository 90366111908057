import React from "react"
import {
  BlogPost,
  BlogPostContent,
  ContactForm,
  MainContainer,
  PageContainer,
  PageHeaders,
  PageHeading,
  PostArticle,
  PostContainer,
  PostContainerInner,
  PostContent,
  SectionSpacer,
} from "../../elements"
import { Layout } from "../../Layout"

interface ContactUsTemplateProps {}

const ContactUsTemplate: React.FC<ContactUsTemplateProps> = ({}) => {
  return (
    <Layout title="Contact Us" pathname="/contact">
      <PageHeaders>
        <MainContainer>
          <PageHeading>
            <h2>Contact Us</h2>
          </PageHeading>
        </MainContainer>
      </PageHeaders>

      <PageContainer>
        <MainContainer>
          <PostArticle>
            <PostContent>
              <ContactForm>
                <input type="text" placeholder="Your Full Name" />
                <input type="email" placeholder="Your Email Address" />
                <textarea placeholder="Your Message" />
                <input type="submit" value="Send" />
              </ContactForm>
            </PostContent>
          </PostArticle>
        </MainContainer>
        <SectionSpacer marginBottom="3em" />
      </PageContainer>
    </Layout>
  )
}
export default ContactUsTemplate
