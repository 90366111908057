import { graphql, useStaticQuery } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import {
  BlogPost,
  PostContainerInner,
  BlogPostContent,
  PostContainer,
  PageHeaders,
  MainContainer,
  PageHeading,
  PageContainer,
  PostArticle,
  PostContent,
  SectionSpacer,
} from "../../elements"
import { Layout } from "../../Layout"
import { capitalize } from "../../utils"

interface AboutUsTemplateProps {}

const AboutUsTemplate: React.FC<AboutUsTemplateProps> = ({}) => {
  return (
    <Layout
      title="About Us"
      pathname="/about-us"
      description="We began Best For Your Feet since we're fixated on shoes and shoe shopping."
    >
      <PageHeaders>
        <MainContainer>
          <PageHeading>
            <h2>About Bestforyourfeet.com</h2>
          </PageHeading>
        </MainContainer>
      </PageHeaders>

      <PageContainer>
        <MainContainer>
          <PostArticle>
            <PostContent>
              <p>
                We began Best For Your Feet since we're fixated on shoes and
                shoe shopping. We love assisting individuals with tracking down
                the right shoes for their feet, and in the wake of assisting
                various companions with shoes we concluded the time had come to
                help the world through the force of the web.
              </p>
              <p>
                We cover loads of various shoe themes here, including strolling
                shoes, b-ball shoes, and shoes for foot conditions like impact
                point torment and plantar fasciitis.
              </p>
              <p>
                Item research is done through experience with many shoes and
                perusing huge number of audits online pretty much all various
                sorts of shoes. We have a really genuine determination measure
                for the shoes where we go inside and out into the general
                mishmash of each shoe before we suggest it here.
              </p>
              <p>
                We're cheerful you went to the site. Hang out, read loads of
                audits, and afterward choose which shoes are best for you.
              </p>
            </PostContent>
          </PostArticle>
        </MainContainer>
        <SectionSpacer marginBottom="3em" />
      </PageContainer>
    </Layout>
  )
}
export default AboutUsTemplate
