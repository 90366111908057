import { graphql, useStaticQuery } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import {
  MainContainer,
  PageContainer,
  PageHeaders,
  PageHeading,
  PostArticle,
  PostContent,
  SectionSpacer,
} from "../../elements"
import { Layout } from "../../Layout"

interface PrivacyTemplateProps {}

const PrivacyTemplate: React.FC<PrivacyTemplateProps> = ({}) => {
  const { Privacy } = useStaticQuery(graphql`
    query {
      Privacy: allMdx(filter: { fileAbsolutePath: { regex: "/legal/" } }) {
        edges {
          node {
            body
            frontmatter {
              date
              title
              description
            }
          }
        }
      }
    }
  `)

  const privacy = Privacy.edges[0].node

  console.log(privacy)

  return (
    <Layout
      title={privacy.frontmatter.title}
      description={privacy.frontmatter.description}
      pathname="/privacy"
    >
      <PageHeaders>
        <MainContainer>
          <PageHeading>
            <h2>Privacy</h2>
          </PageHeading>
        </MainContainer>
      </PageHeaders>

      <PageContainer>
        <MainContainer>
          <PostArticle>
            <PostContent>
              <MDXRenderer>{privacy.body}</MDXRenderer>
            </PostContent>
          </PostArticle>
        </MainContainer>
        <SectionSpacer marginBottom="3em" />
      </PageContainer>
    </Layout>
  )
}
export default PrivacyTemplate
