import { Link } from "gatsby"
import React from "react"
import { Layout } from "../../Layout"

interface PageNotFoundTemplateProps {}

const PageNotFoundTemplate: React.FC<PageNotFoundTemplateProps> = ({}) => {
  return (
    <Layout
      title="Page Not Found"
      description="Best for your feet - Page Not Found"
    >
      <div style={{ textAlign: "center", padding: "200px 0px" }}>
        <h1 style={{ marginBottom: "30px" }}>Page Not found</h1>
        <p>
          <Link to="/" style={{ textDecoration: "underline" }}>
            Get Back Home
          </Link>
        </p>
      </div>
    </Layout>
  )
}
export default PageNotFoundTemplate

// article_category
//           ? { content: article_category, property: `article:section` }
//           : null,

// type && type === `article`
//           ? {
//               name: `article:opinion`,
//               property: `article:opinion`,
//               content: `false`,
//             }
//           : null,
